import logo from './graphics/pplogony.png'
import checkbox from './graphics/Checkmark.png'
import clock from './graphics/Illustrasjoner.png'
import planner from './graphics/planner.png'

const contactEmail = 'krister@prosjektplanlegger.no'
const contactPhone = '+47 960 96 088'

function App() {

  const handleSendEmailOnButtonClick = () => {
    window.location.href = `mailto:${contactEmail}` 
  }

  return (
    <div className="App">
      <div className="header">
        <div className="inner">
          <img src={logo} alt="logo" />
          <a href="#priser">PRISER</a>
          <a href="#kontakt">KONTAKT</a>
          <button onClick={handleSendEmailOnButtonClick}>Bestill demo</button>
        </div>
      </div>
      <div className="body">
        <div className="inner">
          <div className="promoLarge">
            Brukervennlig ressurs- og prosjektplanlegger
            for entreprenører og håndverkere
          </div>
          <div className="promoSmall">
            God planlegging leder til god lønnsomhet
          </div>
          <div className="overview">
            <div className="checkboxes">
              <h3>Med prosjektplanleggeren får du enkelt oversikt</h3>
              <div className="checkboxline">
                <img src={checkbox} alt="checkbox" />
                Et optimalt system for ressursplanlegging
              </div>
              <div className="checkboxline">
                <img src={checkbox} alt="checkbox" />
                Visuell fremstilling av pågående og kommende prosjekter
              </div>
              <div className="checkboxline">
                <img src={checkbox} alt="checkbox" />
                Effektiv allokering av ressurser ved få tastetrykk
              </div>
              <div className="checkboxline">
                <img src={checkbox} alt="checkbox" />
                Komplett oversikt over mannskap og kapasitet
              </div>
              <div className="checkboxline">
                <img src={checkbox} alt="checkbox" />
                Digital planlegging – alltid tilgjengelig
              </div>
            </div>
            <div className="clock">
              <img src={clock} alt="clock" />
            </div>
          </div>
          <div className="promoMedium">
            Når du har oversikt over oppdragsmengde, hvor mye kapasitet du har
            tilgjengelig, og i tillegg en visuell fremstilling som gjør det enkelt å ha
            kontroll - har du alle forutsetninger for god planlegging
          </div>
          <div className="startButton">
            <button onClick={handleSendEmailOnButtonClick}>Start</button>
          </div>
          <div className="planner">
            <img src={planner} alt="planner" />
          </div>
          <div id="priser" className="priser">
            <h1>Ta kontakt for informasjon om priser.</h1>
          </div>
        </div>
      </div>
      <div className="footer">
        <div className="inner">
          <div className="info">
            <b>Driftet og utviklet av:</b>
            <div>Surf Labs AS</div>
            <div>Frøyerhagen 8</div>
            <div>4328 Sandnes</div>
          </div>
          <div id="kontakt" className="contact">
            <b>Kontakt oss her:</b>
            <div>Krister Egeland</div>
            <a href={`mailto:${contactEmail}`}>{contactEmail}</a>
            <a href={`tel:${contactPhone}`}>{contactPhone}</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
